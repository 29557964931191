import React, { useEffect, useState, useRef } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { Autocomplete, Box, Input, InputBase, TextField, Typography, useTheme, Modal} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import { useCSN } from "../../components/CSNProvider.jsx";
import { toast } from "react-toastify";
import ReactApexChart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";

import {
  MenuItem,
  Select,
} from "@mui/material";
import { useUser } from "../../components/UserProvider.js";
import Loader from "../../components/Loader/Loader.js";
import ExportToExcel from "../../components/ExportToExcel.js";

const InsightMatrics2 = () => {
  const {csn} = useCSN()
  const navigate = useNavigate()
  const [customerData, SetCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState([]);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(1);  
  const [selectedInsightContract, SetselectedInsightContract] = useState(null);
  const [DoubleFilter, setDoubleFilter] = useState([]);

  const [subcustomerTableData, SetCustomerTableData] = useState([]);
  const [originalCustomerData, setOriginalCustomerData] = useState([]);

  const [subcustomerAllData, SetCustomerAllData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [branchfilteredData, setBranchFilteredData] = useState([]);
  
  
  const [productlinecount, SetProductLineCount] = useState();
  const [productlineproratedcount, SetProductLineProratedCount] = useState();
  
  
  const [riskcategory, SetRiskCategory] = useState();
  const [loading, SetLoading] = useState(true);

  const [filteredResultss, setFilteredResults] = useState([]);
  
  const [error, setError] = useState(null);
  const [accountsname, setAccountsName] = useState(null)
  const [tableData, setTableData] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [risKColor, setRisKColor] = useState(null);
  const [adjustedRisKColor, setAdjustedRisKColor] = useState("all");
  console.log("_______________________- ~ InsightMatrics2 ~ adjustedRisKColor:", adjustedRisKColor)
  
  console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ InsightMatrics2 ~ risKColor:", risKColor)
  const [status, setStatus] = React.useState("All Status");
  const [selectedValue, setSelectedValue] = useState([]);
  const [searchBranch, setSearchBranch] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const [branches, setBranches] = useState([]);
  const [page, setPage] = useState(0);
  const [risKColorbool, setRisKColorBool] = useState(false);
  console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ InsightMatrics2 ~ risKColorbool:", risKColorbool)

  const [data, setData] = useState([]);
  console.log("________________data",data);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const {token, user} = useUser()

  const headers = {
    "Authorization": `Bearer ${token}`
	}
  const [filteredProductLineCount, setFilteredProductLineCount] = useState({});

  const handleOpenModel = (insight_id ,contract_id) => {
    setOpen(true);
    axios.get(BACKEND_BASE_URL + `v1/api/get_insight_metrics_contract/${insight_id}/${contract_id}`, {headers})
      .then((response) => {
        if (response.status === 200) {
          SetselectedInsightContract(response?.data);
          setSelectedSubscription(response?.data[0])
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const ExportedAll = async () => {
    const accountIds = selectedValue.map(account => account.csn);
    const params = new URLSearchParams();
    params.append('page', page + 1);

    if (searchBranch) {
      params.append('branch', encodeURIComponent(searchBranch.value));
    }

    if (status){
      params.append('status', encodeURIComponent(status));
    }

    if (accountIds.length > 0) {
      params.append('accounts', JSON.stringify(accountIds)); 
    }

    if (risKColor){
      params.append('color', encodeURIComponent(risKColor));
    }

    const apiUrl = `${BACKEND_BASE_URL}v1/api/insight_exported_files/${csn ? `${csn}` : ''}?${params.toString()}`;

    try {
      const response = await axios.get(apiUrl, { headers });

      if (response.status === 202) {
        setError(null);
        toast.success(response?.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Error occurred while Export.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setError(error?.response?.data?.Response?.message || "An unknown error occurred.");

      toast.error("Error occurred while displaying accounts.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }


  const handleExportToBackend = () => {
    // Data conversion to CSV
    const exportFields = [
      'id', 'contractNumber', 'customerCSN', 'user_assign', 'renewal_person', 
      'Account', 'AccountStatus', 'contract_status', 'branch', 
      'productLineCode', 'seatsPurchased', 'usersAssigned', 
      'seatsInUse', 'assignmentRate', 'utilisationRate', 
      'usageRate', 'riskCategory', 'engagementCategory', 'tenantId'
    ];
    const filteredData = data.map((row) =>
      exportFields.reduce((acc, field) => {
        acc[field] = row[field];
        return acc;
      }, {})
    );

    // Convert data to CSV
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Insight Data");
    const csvData = XLSX.write(workbook, { bookType: 'csv', type: 'array' });

    // Prepare file
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const fileName = `insight_trisita_${user.first_name}_${user.last_name}_${currDate}_${currTime}.csv`;

    // Send file to backend
    const formData = new FormData();
    formData.append('file', blob, fileName);
    
    // axios
    //   .post('/api/upload-insight-file', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   })
    //   .then((response) => {
    //     console.log('File successfully uploaded:', response.data);
    //   })
    //   .catch((error) => {
    //     console.error('Error uploading file:', error);
    //   });
  };


  useEffect(() => {
    if (token){
    GetAllBranch();
    }
  }, [token]);


  const GetAllBranch = () => {
    setStatus("All Status");
    axios.get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.Branch.map(item => {
            const name = item.branch_name;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          setBranches(data)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    if (risKColor) {
      if (risKColor === "Unknown"){
        const filteredData2 = originalCustomerData.filter(item => {
          return item['riskCategory'] === "";
        });
      SetCustomerData(filteredData2);
      SetCustomerTableData(filteredData2)
      setFilteredResults(filteredData2)
      }
      else{
        const filteredData2 = originalCustomerData.filter(item => {
        return item['riskCategory'] === risKColor;
      });
      SetCustomerData(filteredData2);
      SetCustomerTableData(filteredData2)
      setFilteredResults(filteredData2)
      }
    }
  }, [risKColor]);
  

  const categories = productlinecount ? Object.keys(productlinecount) : [];

  const options = {
    chart: {
      height: 350,
      type: 'bar',
    },
    xaxis: {
      labels: {
      },
      categories: categories,
    },
    yaxis: {
      title: {
        text: 'Count',
      },
    },
    colors: ['#007BFF', '#28A745', '#FFC107'],
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: '80%',
      },
    },
  };

  const series = [
    {
      name: 'Seats Purchased',
      data: categories.map(category => (productlinecount[category] ? productlinecount[category].seats_purchased_count : 0)),
    },
    {
      name: 'User Assigned',
      data: categories.map(category => (productlinecount[category] ? productlinecount[category].users_assigned_count : 0)),
    },
    {
      name: 'Seats In Use',
      data: categories.map(category => (productlinecount[category] ? productlinecount[category].seats_in_use_count : 0)),
    },
  ];

  const prorated_categories = productlineproratedcount ? Object.keys(productlineproratedcount) : [];

  const prorated_options = {
    chart: {
      height: 350,
      type: 'bar',
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: prorated_categories,
    },
    yaxis: {
      title: {
        text: 'Count',
      },
    },
    colors: ['#007BFF', '#28A745', '#FFC107'],
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: '80%',
      },
    },
  };

  const prorated_series = [
    {
      name: 'Seats Purchased',
      data: prorated_categories.map(category => (productlineproratedcount[category] ? productlineproratedcount[category].seats_purchased_count : 0)),
    },
    {
      name: 'User Assigned ProRated',
      data: prorated_categories.map(category => (productlineproratedcount[category] ? productlineproratedcount[category].users_assigned_prorated : 0)),
    },
    {
      name: 'Seats In Use ProRated',
      data: prorated_categories.map(category => (productlineproratedcount[category] ? productlineproratedcount[category].seats_in_use_prorated : 0)),
    },
  ];

  const risk_category = riskcategory ? Object.keys(riskcategory) : [];

  const risk_series = [
    {
      name: "contracts",
      data: riskcategory ? Object.entries(riskcategory).map(([category, value]) => value) : [],
    },
  ];

  useEffect(() => {
    if (!risKColor) {
      
      handleClear()
    }
  }, [risKColorbool]);


  useEffect(() => {
    console.log("______________adjustedRisKColor updated:", adjustedRisKColor);
  }, [adjustedRisKColor]);

  useEffect(() => {
    if (token){
      handleColorData()
    }

    }, [risKColor]);

  const handleColorData = async () => {

    const accountIds = selectedValue?.map(account => account.csn);
    const params = new URLSearchParams();
    params.append('page', page + 1);

    if (searchBranch) {
      params.append('branch', encodeURIComponent(searchBranch.value));
    }

    if (status){
      params.append('status', encodeURIComponent(status));
    }

    if (accountIds.length > 0) {
      params.append('accounts', JSON.stringify(accountIds)); 
    }

    if (risKColor){
      params.append('color', encodeURIComponent(risKColor));
    }

    console.log("________________risk ke baaad");    

    setIsLoading(true);
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_paginate_insights_metrics_customer/${csn ? `${csn}` : ''}?${params.toString()}`;

    try {
      console.log("________________risk api ke baaad ");
      const response = await axios.get(apiUrl, { headers });
      console.log("________________risk respone ",response);
      const { results, count } = response.data;
      console.log(" ____________ 🚀 ~ useEffect ~ results:", results)
      console.log("________________risk api ke baaad result ke baad ", results);

      setAdjustedRisKColor(results?.color)  
      setData(results?.insight_metrics);
      console.log("____________-results",results?.color);
      setRowCount(count);
      SetLoading(false);
    } catch (error) {
      setData([]);
      setRowCount(0);
      if (error.response) {
        toast.error(error.response.data.error, {
          position: toast.POSITION.TOP_RIGHT
        });
        } else {
          toast.error("An unknown error occurred", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
  } finally {
    setIsLoading(false);
    SetLoading(false);
  }
  }

  const handleBarClick = async (selectedColor, adjustedColor) => {
    console.log("_______________ handleBarClick",adjustedRisKColor);
    
    let updatedColor = selectedColor;
    // if (selectedColor === "Unknown") {
    //   updatedColor = "";
    // }

    setRisKColor(selectedColor)

  //   console.log("_______________ 🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ handleBarClick ~ accountIds:", selected_account)
  //   const accountIds = selected_account.map(account => account.csn);
  //   const params = new URLSearchParams();
  //   params.append('page', page + 1);

  //   if (searchBranch) {
  //     params.append('branch', encodeURIComponent(searchBranch.value));
  //   }

  //   if (status){
  //     params.append('status', encodeURIComponent(status));
  //   }

  //   if (accountIds.length > 0) {
  //     params.append('accounts', JSON.stringify(accountIds)); 
  //   }

  //   if (adjustedRisKColor === updatedColor) {

  //   }
  //   else{
  //     params.append('color', encodeURIComponent(updatedColor));
  //   }


    

  //   setIsLoading(true);
  //   const apiUrl = `${BACKEND_BASE_URL}v1/api/get_paginate_insights_metrics_customer/${csn ? `${csn}` : ''}?${params.toString()}`;

  //   try {
  //     const response = await axios.get(apiUrl, { headers });
  //     const { results, count } = response.data;
  //     setAdjustedRisKColor(results?.color)  
  //     setData(results?.insight_metrics);
  //     console.log("____________-results",results?.color);
  //     setRowCount(count);
  //   } catch (error) {
  //     setData([]);
  //     setRowCount(0);
  //     if (error.response) {
  //   toast.error(error.response.data.error, {
  //     position: toast.POSITION.TOP_RIGHT
  //   });
  // } else {
  //   toast.error("An unknown error occurred", {
  //     position: toast.POSITION.TOP_RIGHT
  //   });
  // }
  // } finally {
  //   setIsLoading(false);
  // }
  }

  
  const risk_options = {
    chart: {
      type: "bar",
      height: 350,
      width: "100%",
      events: {
        click(event, chartContext, config) {
            const selectedColor = risk_category[config.dataPointIndex];
            let updatedColor = selectedColor;
            setRisKColor(updatedColor)
            setAdjustedRisKColor(updatedColor)
            if (updatedColor) {
              handleBarClick(updatedColor, adjustedRisKColor)
            }
            else{
              console.log("no click data")
            }
        }
      },
      
    },

    xaxis: {
      categories: risk_category,
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    dataLabels: {
      position: "top",
    },
  };

  const [rowCount, setRowCount] = useState(0);
  console.log("VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV ~ InsightMatrics2 ~ rowCount:", rowCount)
  console.log("VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV ~ InsightMatrics2 ~ rowCount:", page)


  useEffect(() => {
    fetchData(page);
  }, [ csn, page, token, searchBranch, selectedValue, status]);

  const PAGE_SIZE = 50;

  
  const fetchData = async (page) => {
    console.log("_______________ fetch all");
    const accountIds = selectedValue?.map(account => account?.csn);
    const params = new URLSearchParams();
    params.append('page', page + 1);

    if (searchBranch) {
      params.append('branch', encodeURIComponent(searchBranch?.value));
    }

    if (status){
      params.append('status', encodeURIComponent(status));
    }

    if (accountIds.length > 0) {
      params.append('accounts', JSON.stringify(accountIds)); 
    }

    // if (risKColor){
    //   params.append('color', encodeURIComponent(risKColor));
    // }

    if (risKColor){
      params.append('color', encodeURIComponent(risKColor));
    }
    


    setIsLoading(true);
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_paginate_insights_metrics_customer/${csn ? `${csn}` : ''}?${params.toString()}`;

    try {
      const response = await axios.get(apiUrl, { headers });
      const { results, count } = response?.data;
      setData(results?.insight_metrics);
      setAdjustedRisKColor(results?.color)
      setRowCount(count);
      SetLoading(false);
    } catch (error) {
      setData([]);
      setRowCount(0);
      if (error.response) {
    toast.error(error.response.data.error, {
      position: toast.POSITION.TOP_RIGHT
    });
  } else {
    toast.error("An unknown error occurred", {
      position: toast.POSITION.TOP_RIGHT
    });
  }
} finally {
  setIsLoading(false);
  SetLoading(false);

}
  }

  useEffect(() => {
    setPage(0); // Reset the page to 0
    setRowCount(0);
  }, [searchBranch, selectedValue, status, risKColor]);



  useEffect(() => {
    if (token){
    // handleClear()
		// getAllCustomer();
    getAllCustomerDetails();
  }
	}, [csn, token, searchBranch, selectedValue, status, risKColor]);

  const getAllCustomerDetails = () => {
    const accountIds = selectedValue?.map(account => account?.csn);
    const params = new URLSearchParams();
    // params.append('page', page + 1);

    if (searchBranch) {
      params.append('branch', encodeURIComponent(searchBranch.value));
    }

    if (status){
      params.append('status', encodeURIComponent(status));
    }

    if (accountIds.length > 0) {
      params.append('accounts', JSON.stringify(accountIds)); 
    }

    if (risKColor){
      console.log("____________ adjustedRisKColor",adjustedRisKColor);
      console.log("____________ risKColor",risKColor);
      
    if (adjustedRisKColor === risKColor)
    {
    }
    else{
      params.append('color', encodeURIComponent(risKColor));

    }
    }

    if (risKColor){
    params.append('color', encodeURIComponent(risKColor));
    }
    

    
    // setSearchTerm("")
    // setStatus("All Status");
    // setSelectedValue([]);
    // setSearchBranch(null);
    // SetLoading(true)
    // setIsLoading(true)
    // const apiUrl = `${BACKEND_BASE_URL}v1/api/get_details_insights_metrics_customer/${csn ? `${csn}` : ''}`;
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_details_insights_metrics_customer/${csn ? `${csn}` : ''}?${params.toString()}`;
		axios
    .get(apiUrl, { headers })
    .then((response) => {
        if(response.status === 200) {
        SetProductLineCount(response?.data?.product_line_code);
        SetProductLineProratedCount(response?.data?.prorated_product_line_count);
        SetRiskCategory(response?.data?.risk_category)
          setLastUpdated(response?.data?.last_updated)
          setDoubleFilter(null);
          SetLoading(false);
          setIsLoading(false)
          setError(null);

          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error(response.data.error, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }).catch((error) => {
        SetProductLineCount([]);
        SetProductLineProratedCount([]);
        SetRiskCategory([])
        setIsLoading(false)
        setError(error.response.data?.error);
        SetLoading(false);
        toast.error(error.response.data?.error, {
          position: toast.POSITION.TOP_RIGHT
        })
      });
	}

  const getAccountsData = () => { 
    axios.get(BACKEND_BASE_URL + `v1/api/account_dropdown/`, {headers})
    .then((response) => {
      if (response.status === 200) {
        const data = response.data?.accounts?.map(item => {
          const name = item.name;
          const csn = item.csn;
          const id = item.id;
          return {
            label: name,
            csn: csn,
            id: id,
          };
        });
        const uniqueLabels = new Set();

        const uniqueData = data.filter(item => {
          if (!uniqueLabels.has(item.csn)) {
            uniqueLabels.add(item.csn);
            return true;
          }
          return false;
        });
        setAccountsName(uniqueData)
      }
    })
   };

  useEffect(() => {
    if (token){
    getAccountsData()
    }
  }, [token])  

  const getRowId = (row) => row.id;
  const columns = [
    
    { field: "id", headerName: "Serial No.", width: 120,
    renderCell: (params, index) => (
      (
        <div>
          <button
            onClick={() => handleOpenModel(params?.row.id, params?.row.contractNumber)}
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      )
    ),},
    { field: "contractNumber", headerName: "Contract#", width: 150},
    { field: "customerCSN", headerName: "customerCSN", width: 150},
    ...(user.user_type !== "Client"
      ? [
    // { field: "bd_person", headerName: "BD Person Name", width: 200 },
    // { field: "renewal_person", headerName: "Renewal Person Name", width: 200 }, 
    {
      field: "bd_person",
      headerName: "BD Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "renewal_person",
      headerName: "Renewal Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    ]: []),
    { field: "Account", headerName: "Account", width: 200,
      renderCell: (params) => {
        const { value: Account } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {Account?.length > maxChars ? Account : Account?.slice(0, maxChars)}
          </div>
        );
        },
    },
    { field: "AccountStatus", headerName: "Autodesk Account Status", width: 120 },
    { field: "contract_status", headerName: "Trisita Account Status", width: 120 },
    { field: "subs_end_date", headerName: "Subs End Date", width: 120 },
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    { field: "productLineCode", headerName: "productLineCode" , width: 200},
    { field: "seatsPurchased", headerName: "seatsPurchased" , width: 200},
    { field: "usersAssigned", headerName: "usersAssigned", width: 200},
    { field: "seatsInUse", headerName: "seatsInUse", width: 200},
    // { field: "usersAssignedProRated", headerName: "usersAssignedProRated", width: 200},
    // { field: "seatsInUseProRated", headerName: "seatsInUseProRated", width: 200},
    { field: "assignmentRate", headerName: "assignmentRate", width: 200},
    { field: "utilisationRate", headerName: "utilisationRate", width: 200},
    { field: "usageRate", headerName: "usageRate", width: 200},
    { field: "riskCategory", headerName: "riskCategory", width: 200},
    { field: "engagementCategory", headerName: "engagementCategory", width: 200},
    { field: "tenantId", headerName: "tenantId", width: 200},
  ];

  const filterData = (data) => {
    return data.filter((row) => {
      return Object.values(row).some(
        (value) => {
          if(searchTerm?.label){
            return value && value.toString().toLowerCase().includes(searchTerm?.label?.toLowerCase())
          }else{
            return value
          }
        }
        );
      });
    };


  const filteredData = filterData(subcustomerTableData);


  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setStatus(selectedValue);
  
    if (selectedValue) {
  
      if (selectedValue === 'All Status') {
        
      }
      else{
        const filteredResults =  branchfilteredData?.filter((item) => (item['contract_status'] === selectedValue))
        SetCustomerTableData(filteredResults);
        setOriginalCustomerData(filteredResults);
        
        const productLineCodesSet = new Set(filteredResults.map((item) => item['productLineCode']));
        const productLineCodes = Array.from(productLineCodesSet);
        setFilteredResults(productLineCodes);
      }
  
    } else {
      // getAllCustomer();
    }
  };


  const handleClear = () => {
    setSelectedValue([]);
    setSearchBranch(null);
    setStatus("All Status");
    setRisKColor(null)
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'Not Available';
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const formattedDate = formatLastUpdated(lastUpdated);


  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const fileName = `insight_trisita_${user.first_name}_${user.last_name}_${currDate}_${currTime}`;

  const processedData = data?.map((item) => ({
    ...item,
    bd_person: item.bd_person_name ? item.bd_person_name.join(', ') : '',
    renewal_person: item.renewal_person_name ? item.renewal_person_name.join(', ') : '',
  }));


  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50); // Default page size
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  
  
  return (
    <>
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="User Insight Metrics"  />
      </Box>
      <div className="text-right mb-5 mt-5 gap-3  flex justify-end">
          <div className="relative group">
            <span className="text-gray-600">Last Updated</span>
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max px-4 py-2 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {formattedDate}
              </div>
          </div>
          <button
            onClick={() => navigate('/insight_metrics_tabular')}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] w-[140px] text-center, text-lg"
          >
           Tabular View 
          </button>
      <button
            onClick={() => handleClear()}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] w-[100px] text-center, text-lg"
          >
            All 
          </button>
      <Box  display="flex" marginBottom={2} justifyContent="end" alignItems="center">
            {branches ?  (
              <Autocomplete
                value={searchBranch}
                onChange={(event, newValue) => {
                  setSearchBranch(newValue);
                }}
                options={branches}
                autoSelect
                getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Branch" variant="outlined" />
                )}
              />
            ) :(
              // <CircularProgress />
              <Autocomplete
                value={null} 
                disabled
                autoSelect
                getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Branch"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <Typography variant="body2" color="textSecondary">
                            Loading...
                          </Typography>
                        </>
                      ),
                    }}
                  />
                )}
              />
            
            )
          }
      </Box>

      <Box
          sx={{ ml:  { md:2}  ,width:'200px' }}>
            <Select
              value={status}
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleChange}
              sx={{ width: "100%", height: "52px" }}
              SelectDisplayProps={{
                style: { paddingTop: 8, paddingBottom: 8 },
              }}
            >
              <MenuItem value={"All Status"}>All Status</MenuItem>
              <MenuItem value={"Active"}>Active</MenuItem>
              <MenuItem value={"Expired"}>Expired</MenuItem>
            </Select>
          </Box>

        <Box sx={{ ml: { md: 2 }, minWidth: "200px" }}>
                {accountsname ? (
                  <Autocomplete
                  value={selectedValue} 
                  onChange={(event, newValues) => {
                    setSelectedValue(newValues);
                  }}
                  options={accountsname}
                  autoSelect
                  multiple // Add multiple prop for multi-select
                  getOptionLabel={(option) => `${option.label} (${option.csn})`}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select an Account"
                      variant="outlined"
                    />
                  )}
                />
                ) : (
                  // <CircularProgress />
                  <Autocomplete
                    value={null}
                    disabled
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Account"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Loading...
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Box>
      </div>
      <Box sx={{border:'0.8px solid' , borderColor:colors.grey[900]}}>
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
          <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Data of direct seat metrics i.e. 1:1 association between product pool and agreement (without prorated)
          </Typography>
        </Box>
        <div id="chart">
        {productlinecount ? (
          <ReactApexChart
          options={options}
          series={series}
          type={options.chart.type}
          height={options.chart.height}
        />
        ) : (
            <p>Loading data...</p>
        )}
        
        </div>
      </Box>

      <Box sx={{border:'0.8px solid' , borderColor:colors.grey[900]}}>
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
          <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Data of direct seat metrics i.e. 1:1 association between product pool and agreement (prorated)
          </Typography>
        </Box>
        <div id="chart">
        {productlineproratedcount ? (
          <ReactApexChart
          options={prorated_options}
          series={prorated_series}
          type={prorated_options.chart.type}
          height={prorated_options.chart.height}
        />
        ) : (
            <p>Loading data...</p>
        )}
        
        </div>
      </Box>
      <Box sx={{border:'0.8px solid' , marginTop:3 , borderColor:colors.grey[900]}}>
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
          <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Retention Risk shows summary of the renewal risk for the subscription's contract 
          </Typography>
        </Box>
        <div id="chart">
        {riskcategory ? (
          <ReactApexChart
          options={risk_options}
          series={risk_series}
          type="bar"
          height={350}
        />
        ) : (
          <p>Loading data...</p>
        )}        
        </div>
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
        <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Table Data
          </Typography>
        </Box>
          {/* <Box  display="flex" marginBottom={2} justifyContent="end" alignItems="center">
        <input
        type="text"
        placeholder="Search..."
        value={searchTerm?.label ? searchTerm?.label : ""}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="max-w-[340px] w-full h-[52px] border px-3 mt-5"
      />
      </Box> */}
        {loading ? (
          <p>Loading data...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          
        <>
         <button
            onClick={() => ExportedAll()}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] w-[100px] text-center, text-lg"
          >
            Export All 
          </button>

      <DataGrid
        rows={processedData}
        columns={columns}
        pageSize={PAGE_SIZE}
        rowCount={rowCount}
        pagination
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        loading={loading}
        checkboxSelection
        disableRowSelectionOnClick
        components={{
          Toolbar: () => (
            <GridToolbar
              csvOptions={{
                fileName: user
                  ? `insight_trisita_${user.first_name}_${user.last_name}_${currDate}_${currTime}`
                  : `insight_trisita_default_${currDate}_${currTime}`,
                fields: [
                  'id', 'contractNumber', 'customerCSN', 'user_assign',
                  'renewal_person', 'Account', 'AccountStatus',
                  'contract_status', 'branch', 'productLineCode',
                  'seatsPurchased', 'usersAssigned', 'seatsInUse',
                  'assignmentRate', 'utilisationRate', 'usageRate',
                  'riskCategory', 'engagementCategory', 'tenantId'
                ],
              }}
            />
          ),
        }}
      />
    

          
      </>
      )}
      </Box>
    </Box>
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[500px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
      <div className="h-full">
      {selectedInsightContract?.length === 0 ? (
        <>No Data</>
      ): (
        <>
        <div className="h-10 mb-4">
          {selectedInsightContract?.map((subscription, index) => (
            <button
              key={subscription.id}
              className={`px-3 py-2 mr-2 border rounded ${
                (index === 0 && !selectedSubscription) || (selectedSubscription && selectedSubscription.id === subscription.id) ? 'bg-gray-300' : ''
              }`}
              onClick={() => setSelectedSubscription(subscription)}
            >
              {subscription.id}
            </button>
          ))}
        </div>
          {selectedSubscription && (
            <>
            <div className="text-black flex border-b border-gray-300 h-10">
                <Link
                  to={"#"}
                  className={`${
                    tab === 1
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(1)}
                >
                  End Customer
                </Link>
                <Link
                  to={"#"}
                  className={`${
                    tab === 2
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(2)}
                >
                  Reseller
                </Link>
                <Link
                  to={"#"}
                  className={`${
                    tab === 3
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(3)}
                >
                  Distributor
                </Link>
                <Link
                  to={"#"}
                  className={`${
                    tab === 4
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(4)}
                >
                  Contract
                </Link>
                <Link
                  to={"#"}
                  className={`${
                    tab === 5
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(5)}
                >
                  Line Items
                </Link>
              </div>
              <div className="p-4 my-4 h-[calc(100%_-_174px)] overflow-auto">
                {tab === 1 && (
                  <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                  End Customer Details
                </h1>
                {selectedSubscription ? (
                  <>
                  <div className="w-full">
                            <div className="flex my-4">
                              <div className="w-[200px]">Subscription#</div>
                              <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                            </div>
                            <div className="flex my-4">
                              <div className="w-[200px]">CSN</div>
                              <div className="whitespace-pre">{selectedSubscription?.endCustomer_account?.endCustomerCsn}</div>
                            </div>
                            <div className="flex my-4">
                            <div className="w-[200px]">Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account?.name}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Address Line 1</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account?.address1}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Address Line 2</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account?.address2}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">City</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account?.city}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">State</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account?.stateProvince}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Country</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account?.country}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Postal</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account?.postalCode}</div>
                          </div>
                         </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                        <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                          Contract Manager Details
                        </h1>
                        {selectedSubscription ? (
                        <>
                        <div className="w-full">
                            <div className="flex my-4">
                            <div className="w-[200px]">First Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.first}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Last Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.last}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Email</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.email}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Phone</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.phone}</div>
                          </div>
                        </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                  </>
                )}
                {tab === 2 && (
                  <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                          Reseller Details
                        </h1>
                        {selectedSubscription ? (
                        <>
                        <div className="w-full">
                          <div className="flex my-4">
                            <div className="w-[200px]"> Opportunity#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div>
                            {/* <div className="flex my-4">
                            <div className="w-[200px]"> Opportunity#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div> */}
                          <div className="flex my-4">
                            <div className="w-[200px]">CSN</div>
                            <div className="whitespace-pre">{selectedSubscription?.accounts_reseller.csn}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.accounts_reseller.name}</div>
                          </div>
                        </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                  </>
                 
                )}
                {tab === 3 && (
                  <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                          Distributor Details
                        </h1>
                        {selectedSubscription ? (
                        <>
                        <div className="w-full">
                            <div className="flex my-4">
                            <div className="w-[200px]"> Subscription#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">CSN</div>
                            <div className="whitespace-pre">{selectedSubscription?.accounts_soldTo.csn}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.accounts_soldTo.name}</div>
                          </div>
                        </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                  </>
                )}
                {tab === 4 && (
                  <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                          Contract Details
                        </h1>
                        {selectedSubscription ? (
                        <>
                        <div className="w-full">
                            <div className="flex my-4">
                            <div className="w-[200px]"> Serial No#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Contract#</div>
                            <div className="whitespace-pre">{selectedSubscription?.contract.contract_number}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Type</div>
                            <div className="whitespace-pre">{selectedSubscription?.contract.subType}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Product Line Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.productLine}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Term</div>
                            <div className="whitespace-pre">{selectedSubscription?.contract.contract_term}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Duration</div>
                            <div className="whitespace-pre">{selectedSubscription?.contract.contract}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Retention Health</div>
                            <div className="whitespace-pre">{selectedSubscription?.ews_retentionHealth}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Sub start date</div>
                            <div className="whitespace-pre">{selectedSubscription?.startDate}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Sub end date</div>
                            <div className="whitespace-pre">{selectedSubscription?.endDate}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Quantity</div>
                            <div className="whitespace-pre">{selectedSubscription?.quantity}</div>
                          </div>
                        </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                  </>
                )}
                {tab === 5 && (
                  <>
                    <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black"> Line Items 1 Details </h1>
                    {selectedSubscription ? (
                        <>
                        <div className="w-1/2">
                        <div className="flex my-4">
                            <div className="w-[200px]"> Serial#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Quantity</div>
                            <div className="whitespace-pre">{selectedSubscription?.quantity}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Seats</div>
                            <div className="whitespace-pre">{selectedSubscription?.seats}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Deployment</div>
                            <div className="whitespace-pre">{selectedSubscription?.deployment}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Status</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionStatus}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Asset Status</div>
                            <div className="whitespace-pre">{selectedSubscription?.productStatus}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Support Program</div>
                            <div className="whitespace-pre">{""}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Program Eligibility</div>
                            <div className="whitespace-pre">{""}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Renew</div>
                            <div className="whitespace-pre">{""}</div>
                          </div>
                        </div>
                        </>
                          ) : (
                            <p>Loading data...</p>
                          )}
  
                  </>
                )}
              </div>
            </>
          )}
        </>
       )}
        <div className="border-t">
          <div className="pt-4 text-right">
            <button
              className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
              onClick={() => {
                setOpen(false);
                setSelectedSubscription(null);
              }}
            >
              Close
            </button>
          </div>
        </div>
       </div>
      </div>
    </Modal>
      {
        isLoading && <Loader />
      }
    </>
  );
};

export default InsightMatrics2;