import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { Box, Typography, useTheme, Tooltip, Select, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";


const ProductMaster = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [allProductMasterData, SetAllProductMasterData] = useState([]);
  const [productMasterData, SetProductMasterData] = useState([]);
  const [loading, SetLoading] = useState(true);
  console.log("🚀 ~ ProductMaster ~ loading:", loading)
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const {token, user} = useUser()

  const [status, setStatus] = React.useState("Active");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token){
    GetProductMasterData();
    }
  }, [token, status]);


  const GetProductMasterData = () => {
    const params = new URLSearchParams();
 
    if (status){
      params.append('status', encodeURIComponent(status));
    }
      axios.get(BACKEND_BASE_URL + `v1/product/product_master/?${params.toString()}`, {headers})
      .then((response) => {
        if (response.status === 200) {
          SetAllProductMasterData(response?.data?.product_master);
          SetProductMasterData(response?.data?.product_master);
          setSearchedData(response?.data?.product_master);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getRowId = (row) => row.id;

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
          <button
            className="text-red-600"
            onClick={() => navigate(`/update_product_master/${params.id}`)}
          >
            <span className="table-cell-truncate">{params.value}</span>
          </button>
        </Tooltip>
      ),
    },
    { field: "product_category_name", headerName: "Product Category", width: 200 },
    { field: "acv_price_decimal", headerName: "ACV Price" },
    { field: "dtp_price_decimal", headerName: "DTP Price" },
    { field: "uom_name", headerName: "UOM" },
    { field: "oem_name", headerName: "OEM" },
    { field: "gst_type_name", headerName: "GST Type", width: 150 },
    { field: "gst_ammount_decimal", headerName: "Total Amount(Inc GST)", width: 150 },
    { field: "status_name", headerName: "Status", width: 150 },
    { field: "product_description", headerName: "Product Description", width: 250 },
    { field: "product_sku", headerName: "Product SKU", width: 150 },
    { field: "startDate", headerName: "Start Date", width: 150 },
    { field: "endDate", headerName: "End Date", width: 150 },
    { field: "created_at", headerName: "Created At", width: 150 },
    { field: "updated_at", headerName: "Updated At", width: 150 },
  ];

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();


  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value) {
      const filteredData = filterData(allProductMasterData);
      SetProductMasterData(filteredData);
    } else {
      GetProductMasterData();
    }
  };

  const filterData = (data) => {
    return data.filter((row) => {
      return Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const [searchedData, setSearchedData] = useState([]);

  const handleChange = (event) => {
    console.log("🚀 ~ handleChange ~ event:", event)
    const selectedValue = event.target.value;
    setStatus(selectedValue);

    if (selectedValue) {
      SetLoading(true);
      if (selectedValue === "All Status") {
        SetProductMasterData(searchedData);
      } else {
        const filteredResults = allProductMasterData?.filter((item) => {
          const status_name = item["status_name"];
          return status_name === selectedValue;
        });

        SetProductMasterData(filteredResults);
      }
    } else {
      GetProductMasterData();
    }
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Product Master" subtitle="we are in the same team" />
      </Box>
      <Box sx={{display:"flex"  , gap:'10px' , justifyContent:'end'}}>
      <Box
        sx={{
          ml: { md: 2 },
          height: "50px",
          width: 100,
          minWidth: "200px",
        }}
      >
        <Select
          value={status}
          inputProps={{ "aria-label": "Without label" }}
          onChange={handleChange}
          sx={{ width: "100%" }}
          SelectDisplayProps={{
            style: { paddingTop: 8, paddingBottom: 8 },
          }}
          className="min-h-[52.7px]"
        >
          <MenuItem value={"All Status"}>All Status</MenuItem>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Inactive"}>Inactive</MenuItem>
        </Select>
      </Box>
      <Box display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={() => navigate('/add_product_master')}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] text-center text-lg"
            style={{ whiteSpace: 'nowrap' }}
          >
            Add Product Master
          </button>       
        </Box>
      <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            className="max-w-[340px] w-full h-[52px] border px-3 outline-none"
          />
        </Box>

      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
         {/* <DataGrid rows={allProductMasterData} columns={columns} getRowId={getRowId} /> */}
         <DataGrid
              rows={productMasterData}
              columns={columns}
              getRowId={getRowId}
              components={{
                Toolbar: () => (
                  <div>
                    <GridToolbar
                    csvOptions={{
                      fileName: `product_master_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`,
                      fields: ["name", "product_category_name", "unit_price", "uom_name", "oem_name", "taxable", "taxes", "status_name", "created_at", "updated_at"],
                    }} />
                  </div>
                )
              }}
              checkboxSelection 
              disableRowSelectionOnClick                                                               
            />
      </Box>
      {
        loading && <Loader />
      }
    </Box>
  );
};

export default ProductMaster;
